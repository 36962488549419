// import Config from 'react-config';
const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;
// const BASE_URL = 'https://api.gvine.app';
const WEB_API_KEY = process.env.NEXT_PUBLIC_WEB_API_KEY;
const ANDROID_BUNDLE_ID = process.env.NEXT_PUBLIC_ANDROID_BUNDLE_ID;
const IOS_BUNDLE_ID = process.env.NEXT_PUBLIC_IOS_BUNDLE_ID;

const API_CONSTANT = {
  BASE_URL: `${BASE_URL}/api/v1`,
  WEB_API_KEY: WEB_API_KEY,
  ANDROID_BUNDLE_ID: ANDROID_BUNDLE_ID,
  IOS_BUNDLE_ID: IOS_BUNDLE_ID,
  IN_CODE: '+91',
  REQUEST_OTP: '/signin/otp/request/',
  VERIFY_OTP: '/signin/otp/verify/',
  TOKEN_REFRESH: '/token/refresh/',
  TOKEN_VERIFY: '/token/verify/',
  TOKEN_BLACKLIST: '/token/blacklist/',
  USER: '/user/',
  COMPANY_LIST: '/autocomplete/company/',
  UNIVERSITY_LIST: '/autocomplete/university/',
  JOB_TITLE_LIST: '/autocomplete/jobtitle/',
  USERNAME_EXISTS: '/user/exists/?username=',
  COMMUNITY: '/autocomplete/community/',
  COMMUNITY_SUBSCRIBED: '/community/subscribed',
  JOIN_COMMUNITY: '/community/join/',
  LEAVE_COMMUNITY: '/community/leave/',
  GET_UNIVERCITY: '/autocomplete/university/',
  LIMIT: 10,
  FETCH_SITEMAP_UNAUTHORIZED: '/unauthorized/sitemap/',
  FETCH_FEED_UNAUTHORIZED: '/unauthorized/feed/',
  FETCH_VINES_UNAUTHORIZED: '/unauthorized/vine/',
  FETCH_REFERRAL: '/unauthorized/referral/',
  FETCH_FEED: '/feed/',
  FETCH_POST: '/posts/',
  USER_POSTS: '/user/posts/',
  FETCH_POST_COMMENTS: '/post/comments/',
  FETCH_POST_COMMENTS_UNAUTHORIZED: '/unauthorized/post/comments/',
  USER_PROFILE_COMMENT: '/user/comments/',
  FETCH_TRENDING_COMMUNITI: '/autocomplete/community/',
  FETCH_USER_COMMUNITI: '/autocomplete/community/',
  FETCH_COMMUNITY_TAG: '/flair/list/',
  SUBSCRIBED_COMMUNITI: '/community/subscribed/',
  REPORT_POST: '/report/post/',
  REPORT_POST_COMMENT: '/report/post/comment/',
  LIKE_POST: '/post/promote/',
  DISLIKE_POST: '/post/unpromote/',
  POST_COMMENT_LIKE: '/post/comment/promote/',
  POST_COMMENT_UNLIKE: '/post/comment/unpromote/',
  CREATE_COMMENT_REPLY: '/post/comment/',
  CREATE_COMMENT: '/post/comment/',
  SEARCH_POST: '/search/post/',
  SEARCH_POST_UNAUTHORIZED: '/unauthorized/search/post/',
  COMMUNITY_UNAUTHORIZED: '/unauthorized/community/',
  COMPANY_UNAUTHORIZED: '/unauthorized/company/',
  SHARE_POST: '/post/share/',
  BOOKMARK_POST: '/post/bookmark/',
  UNBOOKMARK_POST: '/post/unbookmark/',
  USER_PROFILE_BOOKMARK: '/user/bookmarks/',
  UI_FLAGS: '/ui/flags/',
  NOTIFICATION: '/notifications/',
  NOTIFICATION_SEEN: '/notifications/seen/',
  FLAIR_LIST: '/flair/list/',
  CREATE_POST: '/post/',
  REMOVE_POST: '/delete/post/',
  REMOVE_POST_COMMENTS: '/delete/post/comment/',
  POST_SEEN: '/post/seen/',
  POST: '/posts/',
  REPORT_COMMUNITY: '/report/community/',
  REPORT_COMMENT: '/report/post/comment/',
  ADMIN_BAN_USER: '/admin/ban/user/',
  ADMIN_DELETE_WARN_POST: '/admin/delete/post/',
  ADMIN_DELETE_WARN_COMMENT: '/admin/delete/post/comment/',
  ADMIN_BOOST_POST: '/admin/boost/post/',
  USER_NEW_COMPANY: '/request/new/company/',
  USER_NEW_JOBTITLE: '/request/new/jobtitle/',
  USER_NEW_UNIVERSITY: '/request/new/university/',
  DEVICES: '/devices/',
  NOTIFICATION_COUNT: '/notifications/unread/count/',
  DELETE_ACCOUNT: '/request/delete/user/',
  RANDOM_USERNAME: '/username/list/',
  VOTE_POLL: '/post/vote/',
  MEETNEWMEMBER: '/meetnewmembers/',
  MARK_ALL_NOTIFICATION: '/notifications/seen/',
  MENTION_USER: '/mention/user/',
  RATING: '/rating/',
  POST_FOLLOW: '/post/follow/',
  POST_UNFOLLOW: '/post/unfollow/',
  ANALYTICS: '/analytics/',
  REPORT_USER: '/report/user/',
  BLOCK_USER: '/block/user/',
  EMAIL_VERIFICATION: '/user/request/email-verification/',
  EMAIL_OTP: '/user/verify/email-otp/',
  SALARIES_UNAUTHORIZED: '/unauthorized/salaries',
  SALARY_PROGRESSION_UNAUTHORIZED: '/unauthorized/salaries/progression',
  SALARIES: '/salaries',
  SALARY_PROGRESSION: '/salaries/progression',
  COMPANY_STAGE: '/autocomplete/companystage',
  JOB_FUNCTION: '/autocomplete/jobfunction',
  LOCATION: '/autocomplete/location',
  SPECIALISATION: '/autocomplete/specialization',
  SENIORITY: '/autocomplete/seniority',
  CREATE_SALARY: '/user/salaries/',
  SALARY_FILTERS: '/salaries/filters/',
  ANALYZE_SALARY: 'user/salaries/analyze/',
  DISCOVER_POSTS: '/post/discovery',
  DISCOVER_POSTS_UNAUTHORIZED: '/unauthorized/discovery'
};

export default API_CONSTANT;
