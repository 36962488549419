import { useRouter } from 'next/router';
import React from 'react';
import { ROUTE } from '../../utils';
import Linkify from 'linkify-react';
import 'linkify-plugin-mention';

const containsURL = text => {
  const urlPattern = new RegExp(
    '(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?',
    'i' // fragment locator
  );
  return urlPattern.test(text);
};

const renderLink = ({ attributes, content }) => {
  const onCLick = () => {
    window.open(attributes.href, '_blank');
  };

  return (
    <label className="text-Action cursor-pointer" onClick={onCLick}>
      {content}
    </label>
  );
};

const RenderContent = ({ data, truncIt }) => {
  const { pathname } = useRouter();

  if (data?.content) {
    let content = data?.content?.replace(/\n /g, '\n');

    return (
      <div
        className={`${
          pathname?.startsWith(ROUTE?.POST_DETAILS) ? 'mt-[12px]' : 'mt-[10px]'
        } text-left heading-large-R`}
      >
        <p
          className={`break-words ${
            pathname?.startsWith(ROUTE?.POST_DETAILS)
              ? 'heading-large-R-inter text-Neutral900 dark:text-Neutral900Dark'
              : 'heading-medium-M-inter text-Neutral800 dark:text-Neutral800Dark'
          } text-Neutral800 dark:text-Neutral800Dark text-left ${
            truncIt ? 'truncate-overflow' : ''
          } `}
          style={{ whiteSpace: 'pre-line' }}
        >
          {containsURL(content) ? (
            <Linkify
              options={{
                render: {
                  url: renderLink
                }
              }}
            >
              {content}
            </Linkify>
          ) : (
            content
          )}
        </p>
      </div>
    );
  }
};

export default RenderContent;
