const title = 'Grapevine: Salary & Career Discussions - Join 350k+ members';
const description =
  'A platform for honest Salary and Career Discussions - Join 350k+ members from top tech companies and startups on Grapevine';

const seo = {
  title,
  description,
  openGraph: {
    type: 'website',
    // locale: 'en_IN',
    //  url: 'https://grapevine.in',
    siteName: 'Grapevine: Salary & Career Discussions - Join 350k+ members',
    site_name: 'Grapevine: Salary & Career Discussions - Join 350k+ members',
    title,
    description,
    images: [
      {
        url: `${process.env.NEXT_PUBLIC_URL}/images/Grapevine-Default-Image.png`,
        alt: 'Grapevine - Salaries & Career'
      }
    ]
  },
  twitter: {
    site: '@join_grapevine',
    cardType: 'summary_large_image',
    handle: '@join_grapevine'
  },
  robotsProps: {
    noarchive: true
    //noimageindex: true
  }
};

export default seo;
