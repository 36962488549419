import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import icons from '../../assets/leftSide';
import Image from 'next/image';
import image from '../../assets/Icons';
import { AppConstant } from '../../constants/AppConstant';
import { useRouter } from 'next/router';
import {
  formatStringInURL,
  hasValue,
  isMobileDevice,
  ROUTE
} from '../../utils';
import Dot from '../Dot';
import { AppContext } from '../../context/AppProvider';
import { colors } from '../../themes';
import { CircularProgress } from '@mui/material';
import { Menu } from '@szhsin/react-menu';
import ViewUser from './ViewUser';
import * as track from '../../utils/MixpanelTrackEvent';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import { generateRandomName } from '../../utils/nameGenerator';

const RenderHeader = ({ data, post, hideBackButton = false }) => {
  const router = useRouter();
  const context = useContext(AppContext);
  const { isAuthenticated } = useSelector(state => state.login);
  const {
    user,
    intraFeed,
    intraFeed: { communityId }
  } = useSelector(state => state);

  const isDarkMode = context?.theme === 'dark';



  const handleBackButtonAction = () => {
    router.back();
  };

  useEffect(() => {
    moment.updateLocale('en', {
      relativeTime: {
        future: '%s',
        past: '%s',
        s: '%ds',
        ss: '%ds',
        m: '%dm',
        mm: '%dm',
        h: '%dh',
        hh: '%dh',
        d: '%dd',
        dd: '%dd',
        w: '%dw',
        ww: '%dw',
        M: '%dmo',
        MM: '%dmo',
        y: '%dy',
        yy: '%dy'
      }
    });
  }, []);

  const RenderHeaderProfile = ({ data }) => {
    return (
      <Image
        priority
        className="w-[28px] h-[28px] rounded-full"
        src={
          data?.community?.profile_image !== null
            ? data?.community?.profile_image
            : image?.communitylogo
        }
        alt="img"
        width={28}
        height={28}
      />
    );
  };

  var diffInDays = moment(data?.created_at).startOf('seconds').fromNow();

  const RenderBottom = ({ data }) => {
    const { isAuthenticated } = useSelector(state => state.login);

    return (
      <div className="w-full max-w-[250px] lg:max-w-md">
        <label className="text-Neutral900 dark:text-Neutral900Dark heading-medium-M flex">
          {!isAuthenticated
            ? `${data?.community?.display_name} Community`
            : data?.community?.display_name}
          {router?.pathname.startsWith(ROUTE?.POST_DETAILS) && (
            <>
              {/* {AppConstant?.ON_S}
              <icons.appTitle w={60} h={18} className="ml-1" /> */}
            </>
          )}
        </label>
        <div
          className="flex items-center"
          onClick={e => {
            e?.stopPropagation();
          }}
        >
          <Menu
            transition
            menuStyle={{ borderRadius: '25px' }}
            menuButton={
              <label
                className="text-Neutral800 dark:text-Neutral800Dark heading-small-R max-w-[180px] sm:max-w-xs justify-center block overflow-hidden text-ellipsis whitespace-nowrap cursor-pointer
                hover:underline
                "
                onClick={e => {
                  e.preventDefault();
                  !isAuthenticated
                    ? isMobileDevice()
                      ? context.downloadAppModalShow()
                      : context.setLoginModal(true)
                    : track.trackUsernameTapL2(
                        data?.id,
                        data?.post_type,
                        data?.community?.display_name,
                        data?.user?.username,
                        data?.created_at,
                        data?.likes_count,
                        AppConstant?.FEED,
                        user?.user?.username
                      );
                }}
              >
                {AppConstant?.BY}{' '}
                {isAuthenticated
                  ? data?.user?.username
                  : generateRandomName(data?.id)}
              </label>
            }
          >
            <div className="bg-white dark:bg-DarkBg rounded-[20px] dark:border-[1.5px] dark:border-Neutral300Dark">
              <div className="w-[273px] menuStyle justify-center">
                <ViewUser data={data} />
              </div>
            </div>
          </Menu>

          {data?.user?.is_company_email_verified && (
            <icons.verified w={14} h={14} className="ml-[4px]" />
          )}
          <Dot className={'bg-Neutral800 mx-[4px]'} />
          {data?.user?.company !== null &&
          data?.user?.company !== 'Stealth' &&
          data?.user?.company !== 'Student' ? (
            <Link
              className="cursor-pointer"
              href={`${ROUTE.COMPANY}/${encodeURIComponent(
                data?.user?.company
              )}`}
              prefetch={false}
            >
              <label className="text-Neutral800 dark:text-Neutral800Dark heading-small-R max-w-[180px] sm:max-w-xs justify-center block overflow-hidden text-ellipsis whitespace-nowrap cursor-pointer">
                {data?.user?.company !== null
                  ? data?.user?.company
                  : AppConstant?.STUDENT}
              </label>
            </Link>
          ) : (
            <label className="text-Neutral800 dark:text-Neutral800Dark heading-small-R max-w-[180px] sm:max-w-xs justify-center block overflow-hidden text-ellipsis whitespace-nowrap">
              {data?.user?.company !== null
                ? data?.user?.company
                : AppConstant?.STUDENT}
            </label>
          )}

          <Dot className={'bg-Neutral800'} />

          <label className="text-Neutral800 dark:text-Neutral800Dark heading-small-R">
            {diffInDays}
          </label>
        </div>
      </div>
    );
  };

  return (
    <>
      {data === undefined ? (
        <div className="w-full flex justify-center pt-[6px]">
          <CircularProgress size={21} />
        </div>
      ) : (
        <div
          className="flex w-full items-center"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex items-center">
            {(router?.pathname.startsWith(ROUTE?.POST_DETAILS) ||
              router.query?.post_id) &&
              !hideBackButton && (
                <div
                  className="globalMargin h-[33px] justify-center flex flex-col cursor-pointer"
                  onClick={handleBackButtonAction}
                >
                  <icons.back
                    color={hasValue(
                      !isDarkMode,
                      colors?.Neutral900,
                      colors?.Neutral900Dark
                    )}
                  />
                </div>
              )}
            <div className="w-[28px] h-[28px] rounded-full mr-[8px] flex">
              <RenderHeaderProfile data={data} />
            </div>
          </div>
          <RenderBottom data={data} />
        </div>
      )}
    </>
  );
};

export default RenderHeader;
