import React, { Fragment, useContext, useEffect, useState } from 'react';
import Image from 'next/image';
import image, { Notification, Setting, UserOutline } from '../assets/Icons';
import { getFromAsync, ROUTE } from '../utils';
import { ASYNC_KEY, AppConstant } from '../constants/AppConstant';
import { useDispatch, useSelector } from 'react-redux';
import { setIsNativeWebView } from '../store/salaries/Reducer';
import { useRouter } from 'next/router';
import SearchInput from './inputs/searchInput';
import { AppContext } from '../context/AppProvider';
import { colors } from '../themes';
import Link from 'next/link';
import { postClear, searchPost } from '../store/search/Action';
import { usePathname } from 'next/navigation';
import icons from '../assets/leftSide';
import PrimaryButton from './buttons/primaryButton';
import { Tooltip } from '@mui/material';
import buildLink from '../services/firebaseDynamicLink';
import { firebaseEvent } from '../services/firebaseAnalytics';

const Header = ({ share_link, pageProps }) => {
  const dispatch = useDispatch();
  const { isNativeWebView } = useSelector(state => state.salaries);
  const { isAuthenticated } = useSelector(state => state.login);
  const [width, setWidth] = useState(500);
  const appContext = useContext(AppContext);
  const post = useSelector(state => state?.postFeed?.post);
  const [appLink, setAppLink] = useState('https://app.grapevine.in/join');

  const postShareLink = pageProps?.data?.item?.share_link;

  const pathname = usePathname();

  const router = useRouter();
  const { query } = router;

  const { theme } = useContext(AppContext);
  const isDarkMode = theme === 'dark';

  const onOpenAppClicked = () => {
    firebaseEvent('mobile_web_download_app_header_btn', {
      postId: query?.data?.length ? query?.data[0] : query?.post_id
    });
  };

  const _isLoginClick = () => {
    appContext.setLoginModal(true);
  };

  const appOpenClick = async () => {
    if (query?.post_id) {
      let payload = {
        postId: props?.pageProps?.data?.id || query?.post_id,
        type: AppConstant?.POST
      };

      const link = await buildLink(payload, '', true);
      setAppLink(link);
      return;
    }

    if (query?.data?.length) {
      let payload = {
        postId: query?.data[0],
        type: AppConstant?.POST
      };

      const link = await buildLink(payload, '', true);
      setAppLink(link);
      return;
    }
    setAppLink('https://app.grapevine.in/join');
  };

  useEffect(() => {
    // if (!postShareLink) {
      appOpenClick();
    // }
  }, [query, postShareLink]);

  useEffect(() => {
    setWidth(global?.window && window.innerWidth);

    const handleResize = () => setWidth(global?.window && window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    (async () => {
      const isNative = await getFromAsync(ASYNC_KEY.NATIVE_WEBVIEW);
      dispatch(setIsNativeWebView(isNative));
    })();
  }, [dispatch]);

  const navigateToHome = () => {
    window.location.href = ROUTE.HOME;
  };
  return (
    <nav
      className={`z-50 w-full bg-White dark:bg-DarkBg px-2 py-3 shadow-[0_0.33px_0_0_#BDC5CD] dark:shadow-[0_0.33px_0_0_#313234] flex items-center  ${
        isNativeWebView && 'hidden'
      }`}
    >
      <div className="xl:container px-6 w-full container-fit flex items-center gap-2 md:gap-4">
        <div className="cursor-pointer">
          <Image
            priority
            src={image.grapevineLogo}
            alt={'logo'}
            className="h-fit w-[112px]"
            onClick={navigateToHome}
          />
          {!isAuthenticated ? (
            <label className="heading-smaller-L font-normal text-grey-60 dark:text-gray-60">
              Join 350k+ Professionals
            </label>
          ) : null}
        </div>

        <div className="flex-1 justify-center flex">
          {isAuthenticated && <SearchBar />}
        </div>

        <div className="flex gap-2 md:gap-4 items-center">
          {isAuthenticated ? (
            <Fragment>
              <Link href={ROUTE.SEARCH} className="block sm:hidden">
                <div className="p-1">
                  <icons.search
                    w={18}
                    h={18}
                    color={
                      pathname === ROUTE.SEARCH
                        ? colors.Primary.DEFAULT
                        : isDarkMode
                        ? colors.darkGrey[70]
                        : colors.grey[70]
                    }
                  />
                </div>
              </Link>
              <Tooltip title="Notification">
                <Link href={'/inbox'}>
                  <Notification
                    stroke={
                      pathname === ROUTE.INBOX
                        ? colors.Primary.DEFAULT
                        : isDarkMode
                        ? colors.darkGrey[70]
                        : colors.grey[70]
                    }
                    isActive={pathname === ROUTE.INBOX}
                    fill={colors.Primary.DEFAULT}
                  />
                </Link>
              </Tooltip>
              <Tooltip title="Profile">
                <Link href={'/you'}>
                  <UserOutline
                    stroke={
                      pathname === ROUTE.YOU
                        ? colors.Primary.DEFAULT
                        : isDarkMode
                        ? colors.darkGrey[70]
                        : colors.grey[70]
                    }
                    isActive={pathname === ROUTE.YOU}
                    fill={colors.Primary.DEFAULT}
                  />
                </Link>
              </Tooltip>
              <Tooltip title="Setting">
                <Link href={'/more'}>
                  <Setting
                    stroke={
                      pathname === ROUTE.MORE
                        ? colors.Primary.DEFAULT
                        : isDarkMode
                        ? colors.darkGrey[70]
                        : colors.grey[70]
                    }
                    isActive={pathname === ROUTE.MORE}
                    fill={colors.Primary.DEFAULT}
                  />
                </Link>
              </Tooltip>
            </Fragment>
          ) : (
            <Fragment>
              {width < 600 ? null : (
                <PrimaryButton ariaLabel={'Sign in'} onClick={_isLoginClick} >
                  {AppConstant.SIGN_IN}
                </PrimaryButton>
              )}

              {width < 600 && (
                <Link
                  // href={postShareLink || appLink}
                  href={appLink}
                  target="_blank"
                  onClick={onOpenAppClicked}
                >
                  <PrimaryButton ariaLabel={'open app'}>Open app</PrimaryButton>
                </Link>
              )}
            </Fragment>
          )}
        </div>
      </div>
    </nav>
  );
};

const SearchBar = () => {
  const router = useRouter();

  const { search } = useSelector(state => state);

  const onSearchClick = () => {
    router.push(ROUTE.SEARCH);
  };

  const onSearchChange = val => {
    searchPost(val);
  };

  const onPostClear = () => {
    postClear();
  };

  return (
    <SearchInput
      rootClass="hidden sm:flex"
      value={search?.searchValue}
      onChange={e => {
        (e?.target?.value.length > 2 || !e?.target?.value.length) &&
          onSearchChange(e?.target?.value);
      }}
      onClick={onSearchClick}
      onClearClicked={onPostClear}
    />
  );
};

export default Header;
